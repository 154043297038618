import { makeStyles } from "@material-ui/core/styles";
import { AppTheme } from "types/AppTheme";

const useStyles = makeStyles((theme: AppTheme) => ({
  "@global": {
    "::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "::-webkit-scrollbar-track": {
      borderRadius: "0.375rem",
      background: "#1E293B",
    },
    "::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover": {
      backgroundImage: "linear-gradient(to top,#3b0764,#6b21a8)",
      borderRadius: "0.375rem",
      border: "none !important",
      // add hover
    },
    "* body": {
      backgroundColor: `${theme.palette.secondary.dark} !important`,
    },
    "#root header": {
      width: "100%",
      height: "55px",
    },
    "#root .main-content": {
      width: "100%",
      height: "calc(100% - 55px)",
    },
    ".custom-dropdown-menu .MuiPopover-paper": {
      backgroundColor: theme.palette.secondary.dark,
      "& .MuiListItem-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    ".custom-autocomplete-chip": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
    ".MuiAutocomplete-popper .MuiAutocomplete-paper": {
      backgroundColor: theme.palette.secondary.dark,
      "& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected=true]": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  root: {
    width: "100%",
    height: "100%",
  },
  formControl: {
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  // Table
  table: {
    backgroundColor: theme.palette.primary.dark,
    minWidth: 750,
  },
  tableCellHead: {
    fontSize: 16,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  // ToolBar
  customizeToolbar: {
    minHeight: `55px !important`,
  },

  toolbarButtons: {
    marginLeft: "auto",
  },

  // NavBar
  buttonGroup: {
    "&.MuiButtonGroup-outlined": {
      bordorColor: "blue !important",
    },
  },
  menuHeader: {
    color: `${theme.palette.other.light} !important`,
    pointerEvents: "none",
    paddingTop: "16px !important",
  },
  navBarMenu: {
    "& .MuiMenu-paper": {
      backgroundColor: `${theme.palette.primary.dark}`,
      minWidth: "8em",
    },
    "& .MuiMenuItem-root": {
      fontSize: "1em",
      marginTop: "0.35em",
    },
  },

  userProfileMenu: {
    "& .MuiMenu-paper": {
      backgroundColor: `${theme.palette.secondary.dark}FF`,
      minWidth: "8em",
    },
    "& .MuiMenuItem-root": {
      fontSize: "1em",
      marginTop: "0.35em",
    },
  },

  profileMenuUserName: {
    paddingTop: "0px !important",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  pageReferringPhysicianMenu: {
    "&:hover": {
      backgroundColor: `${theme.palette.primary.dark} !important`,
    },
  },
  pageButton: {
    "&.MuiButton-root": {
      textTransform: "none",
      color: "white !important",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  profilePhoto: {
    width: "32.5px",
    height: "32.5px",
    borderRadius: "50%",
    color: "#2a2a39",
    fontSize: "0.7rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
  },
  profileMenuPhoto: {
    margin: "0 auto",
    width: "50px",
    height: "50px",
    fontSize: "0.9rem",
    position: "relative",
  },
  profilePhotoIconButton: {
    position: "absolute",
    right: "-0.35em",
    top: "1.15em",
    bottom: 0,
    padding: 0,
    color: `${theme.palette.primary.main}`,
    background: "white !important",
    width: "1em",
    height: "1em",
    "& svg": {
      width: "0.75em",
      height: "0.75em",
    },
  },
  topUnreadCountsBadge: {
    backgroundColor: "white",
    borderRadius: "50%",
    width: "17.5px",
    height: "17.5px",
    position: "absolute",
    color: "black",
    textAlign: "center",
    marginTop: "-30px",
    marginLeft: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
  },
  studyOnlineUser: {
    padding: "0 !important",
    marginLeft: "-10px",
    transition: "none",

    "&.MuiIconButton-root": {
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    },
  },
  studyOnlineUserPhoto: {
    marginRight: 0,
  },
  profilePhotoInput: {
    display: "none",
  },
}));

export default useStyles;
