import { createTheme } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { AppTheme } from "types/AppTheme";

export const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      dark: "rgba(30,41,59,.5)",
      dark_opaque: "rgba(30,41,59,1)",
      main: "#A855F7",
      active: "#9FA8DA",
      light: "#A19FAD",
    },
    secondary: {
      dark: "#0F172A",
      main: "#1A2667",
      active: "#0F1012",
      light: "#475569", // for tag
    },
    common: {
      dark: "#726F7E",
      main: "#F7F7F7",
      active: "#515151",
      light: "#A19FAD",
      bright: "#D0D0D0",
      white: "#FFFFFF",
    },
    shadows: {
      dark: "#5E6267",
    },
    other: {
      dark: "#131416",
      light: "#9ECBF1",
      black: "#000000",
    },
  } as PaletteOptions,
  overrides: {
    MuiSelect: {
      selectMenu: {
        whiteSpace: "break-spaces",
      },
    },
  },
}) as AppTheme;
