import React from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import { AppBar, Box, Chip, Toolbar } from "@material-ui/core";
import RecordingListDialog from "components/RecordingDialog/RecordingListDialog";

import useStyles from "styles/appStyles";
import { useApp } from "hooks/useApp";
import UserProfileMenu from "components/NavBar/UserProfileMenu";
import LeftNavBarLinks from "components/NavBar/LeftNavBarLinks";
import StudyMenu from "components/NavBar/StudyMenu";
import { CakeRounded } from "@material-ui/icons";
import moment from "moment";
import { PRIORITY_DISPLAY } from "components/StudyList/StudyListTable";

const NavBar = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { patient, study } = useApp();
  const [recordingDialogOpen, setRecordingDialogOpen] = React.useState(false);

  return (
    <AppBar position="static" style={{ background: "linear-gradient(to right, #6b21a8, #a855f7)" }}>
      <Box>
        <Toolbar className={classes.customizeToolbar}>
          <LeftNavBarLinks />
          {(pathname.startsWith("/study/") || pathname.startsWith("/report/")) && patient && (
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Chip label={PRIORITY_DISPLAY[study.priority] || PRIORITY_DISPLAY.R} color="secondary" size="small" />
              <h1 style={{ fontWeight: "bold" }}>{patient.name}</h1>
              <Chip label={patient.age} color="secondary" size="small" />
              <Chip label={patient.sex} color="secondary" size="small" />
              <Chip
                icon={<CakeRounded />}
                label={moment(patient.date_of_birth).format("ll")}
                color="secondary"
                size="small"
              />
            </Box>
          )}
          <div className={classes.toolbarButtons}>
            {pathname.startsWith("/study/") && study && (
              <StudyMenu study={study} setRecordingDialogOpen={setRecordingDialogOpen} />
            )}
          </div>
          <UserProfileMenu />
        </Toolbar>
      </Box>
      {study && <RecordingListDialog study={study} open={recordingDialogOpen} onClose={setRecordingDialogOpen} />}
    </AppBar>
  );
};

export default NavBar;
