import React, { FC, MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Collapse from "@material-ui/core/Collapse";
import moment from "moment";
import { Chip } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import cn from "classnames";
import { getSeriesByStudyId } from "api/study";
import useStyles from "styles/reportStyles";
import { SeriesInfoItem } from "components/Report/SeriesInfoItem";
import { NL_EXTENSION_EVENTS } from "utils/constants";
import { sendExtensionMessage } from "utils/extension";
import { Study } from "types/models/Study";
import { StudySeries } from "types/models/StudySeries";
import { PhotoIcon } from "utils/icons";
import { theme } from "styles/theme";

type PatientJacketDialogStudyItemProps = { study: Study };

export const PatientJacketDialogStudyItem: FC<PatientJacketDialogStudyItemProps> = ({ study }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [extended, setExtended] = useState(false);
  const [seriesList, setSeriesList] = useState<StudySeries[]>([]);

  useEffect(() => {
    const getSeriesList = async () => {
      const { results: list } = await getSeriesByStudyId(study.id);
      setSeriesList(list.sort((a, b) => a.number - b.number));
    };

    if (study?.id) {
      getSeriesList();
    }

    return () => {
      setSeriesList([]);
    };
  }, [study.id]);

  const handleItemClick = () => {
    setExtended(!extended);
  };

  function redirectView(studyId: string) {
    if (!sendExtensionMessage({ type: NL_EXTENSION_EVENTS.NEW_LANTERN_VIEWER_OPENED, studyId })) {
      navigate(`/study/${studyId}`);
    }
  }

  const redirectReport = (e: MouseEvent<SVGSVGElement>, studyId: string) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/report/${studyId}`);
  };

  return (
    <>
      <div
        className={cn(classes.studyItemRow, classes.patientJacketDialogStudyItemRow, {
          [classes.studyItemExtended]: extended,
        })}
        data-testid="patient-jacket-item"
        onClick={handleItemClick}
      >
        <div className={classes.patientNameRow}>
          <div
            className={classes.studyItemLabel}
            onClick={() => redirectView(study.id)}
            data-testid="patient-jacket-item-label"
          >
            <p
              className={classes.patientJacketDialogStudyItemDescription}
              data-testid="patient-jacket-item-description"
            >
              {study.description}
            </p>
            <p className={classes.patientJacketDialogStudyItemDateLabel} data-testid="patient-jacket-item-date">
              {moment(`${study.date} ${study.time}`).format("ll HH:mm")}
            </p>
          </div>
          <div className={classes.spacing} />
          <Chip className={classes.modalityIcon} label={study.modalities} data-testid="patient-jacket-item-modality" />
          <Description
            onClick={e => redirectReport(e, study.id)}
            className={classes.reportIcon}
            data-testid="patient-jacket-item-report"
          />
          <PhotoIcon color={theme.palette.other.light} />
          <div className={cn(classes.studyItemLabel, classes.instancesNoLabel)} data-testid="patient-jacket-item-count">
            {study.total_instances}
          </div>
        </div>
      </div>
      <div className={classes.seriesList} data-testid="patient-jacket-item-list">
        <Collapse in={extended}>
          {seriesList.length > 0 &&
            seriesList.map(series => <SeriesInfoItem study={study} series={series} key={series.id} />)}
        </Collapse>
      </div>
    </>
  );
};
