// Example host is mrl.newlantern.ai
let sentryEnv = window.location.host.split(".")[0];
sentryEnv = sentryEnv === "app" ? "production" : sentryEnv;

const config = {
  env: process.env.NODE_ENV,
  apiBaseURL: process.env.REACT_APP_API_URL || "",
  withCredentials: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL.includes("http://localhost") : false,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN || "",
  sentryEnv,
};

export default config;
