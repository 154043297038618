import React, { FC } from "react";
import { Badge, withStyles, Typography } from "@material-ui/core";

import { theme } from "styles/theme";
import useStyles from "styles/studyListStyles";
import { useStudy } from "state/selector";

type StudyPriorityBadgeProps = { badgeContent: number; color: string; background: string };

const StudyPriorityBadge: FC<StudyPriorityBadgeProps> = withStyles(() => ({
  badge: {
    position: "static",
    transform: "none",
    marginLeft: "6px",
    borderRadius: "5px",
    height: "25px",
    minWidth: "25px",
    color: (props: StudyPriorityBadgeProps) => props.color,
    backgroundColor: (props: StudyPriorityBadgeProps) => props.background,
  },
}))(Badge) as FC<StudyPriorityBadgeProps>;

type StudyStatusSummaryProps = { value: string; label: string; showPriorityDisplay: boolean };

export const StudyStatusSummary: FC<StudyStatusSummaryProps> = ({ value, label, showPriorityDisplay }) => {
  const classes = useStyles();
  const { priorityCountByStatus } = useStudy();
  const numRoutine = priorityCountByStatus?.[value]?.R || 0;
  const numUrgent = priorityCountByStatus?.[value]?.T || 0;
  const numAsap = priorityCountByStatus?.[value]?.A || 0;
  const numStat = priorityCountByStatus?.[value]?.S || 0;

  return (
    <Typography className={classes.studyStatusSummary} variant="subtitle2" gutterBottom>
      <span data-testid="study-status-summary-label">{label}</span>
      {showPriorityDisplay && numRoutine > 0 && (
        <StudyPriorityBadge
          badgeContent={numRoutine}
          color={theme.palette.common.white}
          background={theme.palette.success.dark}
          data-testid="study-status-summary-routine"
        />
      )}
      {showPriorityDisplay && numUrgent > 0 && (
        <StudyPriorityBadge
          badgeContent={numUrgent}
          color={theme.palette.common.white}
          background={theme.palette.info.dark}
          data-testid="study-status-summary-urgent"
        />
      )}
      {showPriorityDisplay && numAsap > 0 && (
        <StudyPriorityBadge
          badgeContent={numAsap}
          color={theme.palette.common.white}
          background={theme.palette.warning.dark}
          data-testid="study-status-summary-asap"
        />
      )}
      {showPriorityDisplay && numStat > 0 && (
        <StudyPriorityBadge
          badgeContent={numStat}
          color={theme.palette.common.white}
          background={theme.palette.error.dark}
          data-testid="study-status-summary-stat"
        />
      )}
    </Typography>
  );
};
