import React, { FC, useState } from "react";
import { Box, IconButton } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import moment from "moment";
import { useInterval } from "react-use";
import { theme } from "styles/theme";

type LastUpdatedProps = {
  lastUpdatedTime: number | null;
  onRefresh: () => void;
};

export const LastUpdated: FC<LastUpdatedProps> = ({ lastUpdatedTime, onRefresh }) => {
  const [sinceUpdate, setSinceUpdate] = useState(moment(lastUpdatedTime).fromNow());

  useInterval(() => setSinceUpdate(moment(lastUpdatedTime).fromNow()), 10000);

  const onClick = () => {
    setSinceUpdate("just now");
    onRefresh();
  };

  return (
    <Box
      sx={{ color: theme.palette.common.bright, display: "flex", alignItems: "center", marginLeft: "16px" }}
      data-testid="last-updated"
    >
      <Box data-testid="last-updated-text" sx={{ whiteSpace: "nowrap" }}>
        Last updated {sinceUpdate}
      </Box>
      <IconButton onClick={onClick} data-testid="last-updated-refresh">
        <Refresh />
      </IconButton>
    </Box>
  );
};
