import React, { FC } from "react";
import { useNavigate } from "react-router";
import { theme } from "styles/theme";
import useStyles from "styles/reportStyles";
import { sendExtensionMessage } from "utils/extension";
import { NL_EXTENSION_EVENTS } from "utils/constants";
import { Study } from "types/models/Study";
import { StudySeries } from "types/models/StudySeries";
import { PhotoIcon } from "utils/icons";

type SeriesInfoItemProps = { study: Study; series: StudySeries };

export const SeriesInfoItem: FC<SeriesInfoItemProps> = ({ study, series }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  function redirectView(studyId: string, seriesNo: string) {
    if (!sendExtensionMessage({ type: NL_EXTENSION_EVENTS.NEW_LANTERN_VIEWER_OPENED, studyId })) {
      navigate(`/study/${studyId}?series=${seriesNo}&image=1`);
    }
  }

  return (
    <div className={classes.seriesRow} data-testid="series-info-item">
      <div className={classes.seriesImage}>
        <img src={series.gif} alt="" data-testid="series-info-item-img" />
      </div>
      <div className={classes.seriesInfo}>
        <div className={classes.seriesNoRow}>
          <span className={classes.seriesNoLabel}>S:</span>
          <span className={classes.seriesNo} data-testid="series-info-item-number">
            {series.SeriesNumber}
          </span>
          <PhotoIcon color={theme.palette.other.light} />
          <span className={classes.studyItemLabel} data-testid="series-info-item-instances">
            {series.total_instances}
          </span>
        </div>
        <div
          className={classes.seriesDescription}
          data-testid="series-info-item-description"
          onClick={() => redirectView(study.id, series.SeriesNumber)}
        >
          {series.description}
        </div>
      </div>
    </div>
  );
};
