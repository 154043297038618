import React from 'react'
import "sweetalert2/src/sweetalert2.scss";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { theme } from "styles/theme";
import AppRoutes from "routes";
import AuthGuard from "components/Auth/AuthGuard";
import { AppProvider } from "hooks/useApp";
import { BrowserRouter } from "react-router-dom";
import { Toaster, ToastBar, toast } from "react-hot-toast";

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AuthGuard>
      <BrowserRouter>
        <AppProvider>
          <AppRoutes />
        </AppProvider>
        <Toaster
          position="bottom-right"
          toastOptions={{
            style: {
              background: "#0F172A",
              color: "white",
            },
            success: { duration: Infinity },
            error: { duration: Infinity },
          }}
        >
          {t => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <div
                      style={{ width: "100%", height: "100%", position: "absolute", cursor: "pointer" }}
                      onClick={() => toast.dismiss(t.id)}
                    />
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      </BrowserRouter>
    </AuthGuard>
  </ThemeProvider>
);

export default App;
