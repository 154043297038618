/* global chrome */

import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Tooltip } from "@material-ui/core";

import { NL_EXTENSION_EVENTS } from "utils/constants";
import { ReactComponent as NewLanternLogoIcon } from "../../assets/icons/new-lantern-logo.svg";

const NAV_SECTIONS = [
  {
    title: "Worklist",
    route: "/",
    tooltip: "",
  },
  {
    title: "Admin",
    tooltip: "Admin page is locked",
  },
];

const LeftNavBarLinks = () => {

  const extensionIdEl = document.getElementById("nl-extension-id");
  const extensionId = extensionIdEl ? extensionIdEl.value : null;

  return (
    <>
      <Link component={RouterLink} to="/">
        <NewLanternLogoIcon style={{ height: 35, width: "auto", verticalAlign: "middle", paddingBottom: "9px" }} />
      </Link>
      {NAV_SECTIONS.map(nav => (
        <Tooltip key={nav.title} title={nav.tooltip}>
          <Box ml={3}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={nav.route}
              onClick={e => {
                if (nav.route === "/" && extensionId) {
                  e.preventDefault();
                  chrome.runtime.sendMessage(extensionId, { type: NL_EXTENSION_EVENTS.NEW_LANTERN_WORKLIST_OPENED });
                }
              }}
            >
              {nav.title}
            </Link>
          </Box>
        </Tooltip>
      ))}
    </>
  );
};

export default LeftNavBarLinks;
