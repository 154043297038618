import { Study } from "types/models/Study";
import { PaginationParams } from "types/pagination/PaginationParams";
import { PaginationBody } from "types/pagination/PaginationBody";
import { Patient } from "types/models/Patient";
import api from "api/api";
import { StudySeries } from "types/models/StudySeries";
import { StudyInstance } from "types/models/StudyInstance";
import qs, { ParsedQuery } from "query-string";

export const getStudyListWithParams = async (params: PaginationParams & { patient_id?: string }) => {
  const { data } = await api.get("/api/studies", { params, paramsSerializer: p => qs.stringify(p) });
  return data as PaginationBody<Study>;
};

export const getStudyById = async (studyId: string) => {
  const { data } = await api.get(`/api/studies/${studyId}`);
  return data as Study;
};

export const getPatientById = async (patientId: string) => {
  const { data } = await api.get(`/api/patients/${patientId}`);
  return data as Patient;
};

export const updateStudy = async (studyId: string, payload: Partial<Study>) => {
  const { data } = await api.put(`/api/studies/${studyId}/`, payload);
  return data as Study;
};

export const getInstances = async (params: PaginationParams) => {
  const { data } = await api.get(`/api/instances`, { params });
  return data as PaginationBody<StudyInstance>;
};

export const getSeriesByStudyId = async (studyId: string) => {
  const { data } = await api.get(`/api/series/`, { params: { study_id: studyId } });
  return data as PaginationBody<StudySeries>;
};

export const getInstancesBySeriesId = async (seriesId: number) => {
  const { data } = await api.get(`/api/instances/`, { params: { series_id: seriesId } });
  return data;
};
